<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem">

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form v-loading="loading" :model="form" label-width="9rem" style="padding: 2rem;">
            <el-form-item label="公众号AppId">
              <el-input v-model="form.app_id"></el-input>
            </el-form-item>
            <el-form-item label="公众号AppSecret">
              <el-input v-model="form.app_secret"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="下级加入提醒">
              <el-input v-model="form.xia_ji_jia_ru"></el-input>
              <div class="y-desc">互联网|电子商务 成员加入提醒 :OPENTM207685059</div>
            </el-form-item>
            <el-form-item label="下级升级提醒">
              <el-input v-model="form.xia_ji_sheng_ji"></el-input>
              <div class="y-desc">互联网|电子商务 成员加入提醒 :OPENTM207685059</div>
            </el-form-item>
            <el-form-item label="佣金到账提醒">
              <el-input v-model="form.yong_jin_dao_zhang"></el-input>
              <div class="y-desc">互联网|电子商务 钱包余额变动通知 :OPENTM207527822</div>
            </el-form-item>
            <el-form-item label="用户提现提醒管理员">
              <el-input v-model="form.ti_xian_ti_xing"></el-input>
              <div class="y-desc">IT科技 - IT软件与服务 业务处理提醒 :OPENTM207568720</div>
            </el-form-item>
<!--            <el-form-item label="下级用户下单提醒">-->
<!--              <el-input v-model="form.xia_ji_xia_dan"></el-input>-->
<!--              <div class="y-desc">互联网|电子商务 成员加入提醒 :OPENTM207685059</div>-->
<!--            </el-form-item>-->
            <el-form-item label="级别变动提醒">
              <el-input v-model="form.leve_change_tpl"></el-input>
              <div class="y-desc">互联网|电子商务 会员升级通知 :OPENTM401075189</div>
            </el-form-item>
            <el-form-item label="核销成功提醒">
              <el-input v-model="form.coupon_cashier_tpl"></el-input>
              <div class="y-desc">互联网|电子商务 核销成功提醒 :OPENTM408370546</div>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form v-loading="loading" :model="form" label-width="9rem" style="padding: 2rem;">
            <el-form-item label="关注二维码">
              <y_upload_img v-model="form.follow_qrcode"></y_upload_img>
            </el-form-item>
            <el-form-item label="关注条logo">
              <y_upload_img v-model="form.follow_logo"></y_upload_img>
            </el-form-item>
            <el-form-item label="管理员公众号openid">
              <el-input v-model="form.admin_openid"></el-input>
              <div class="y-desc">互联网|电子商务 成员加入提醒 :OPENTM207685059</div>
            </el-form-item>
            <el-form-item>
              <el-button @click="submit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </YLayoutBodyMenu>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "wxOfficial",
  components: {YLayoutBodyMenu, y_upload_img},
  data() {
    return {
      form: {
        app_id: "",
        app_secret: "",
        xia_ji_jia_ru: "",
        xia_ji_sheng_ji: "",
        yong_jin_dao_zhang: "",
        ti_xian_ti_xing: "",
        // xia_ji_xia_dan: "",
        admin_openid: "",
        follow_qrcode: "",
        follow_logo: "",

        leve_change_tpl: "",
        coupon_cashier_tpl:"",
      },
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$u.api.system.wechatOfficial().then(res => {
        this.form = res;
        this.loading = false;
      })
    },
    submit() {
      this.$u.api.system.wechatOfficialEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc {
  line-height: 1rem
}
</style>